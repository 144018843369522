<template>
  <div class="notfound-view">
    <h1 class="text-h4">Form URL is expired</h1>
    <p class="text-subtitle2">Please contact your administrator!</p>
    <div v-if="showBackButton">
      <q-separator class="q-my-md" />
      <q-btn icon="home" color="primary" @click="$goto('home')"> Back to home </q-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class NotFound extends Vue {
  @Prop({ default: true })
  showBackButton!: boolean
}
</script>

<style lang="scss" scoped>
.notfound-view {
  text-align: center;
  margin-top: 50px;
}
</style>
